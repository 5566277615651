var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog
    ? _c(
        "div",
        [
          _c(
            "vs-popup",
            {
              attrs: {
                title: _vm.$t("vue.dataProtectionHeadline"),
                active: _vm.showDataProtectionPopup,
              },
              on: {
                "update:active": function ($event) {
                  _vm.showDataProtectionPopup = $event
                },
              },
            },
            [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.dataProtectionTextHtml) },
              }),
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.$t("vue.dataProtectionText"),
                      expression: "$t('vue.dataProtectionText')",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error",
                    },
                  ],
                  staticClass: "float-right mt-5",
                  attrs: {
                    type: "border",
                    size: "small",
                    "icon-pack": "feather",
                    icon: "icon-clipboard",
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("vue.copy")) + "\n    ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full lg:w-2/3 mb-base" },
              [
                _c(
                  "vx-card",
                  { attrs: { title: _vm.$t("vue.configureDialog") } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap items-center mb-base" },
                      [
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "6",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mr-2",
                                    staticStyle: {
                                      "min-width": "80px",
                                      "min-height": "80px",
                                    },
                                  },
                                  [
                                    _c(
                                      "croppa",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.myPhoto.img,
                                            expression: "myPhoto.img",
                                          },
                                        ],
                                        attrs: {
                                          placeholder: "Click here",
                                          "show-remove-button": false,
                                          "canvas-color": "white",
                                          "prevent-white-space": "",
                                          "initial-size": "contain",
                                          disable: false,
                                          accept: ".jpeg,.jpg,.gif",
                                          width: 80,
                                          height: 80,
                                          "file-size-limit": 204800,
                                          "placeholder-font-size": 14,
                                        },
                                        on: {
                                          "file-type-mismatch":
                                            _vm.onFileTypeMismatch,
                                          "file-size-exceed":
                                            _vm.onFileSizeExceed,
                                          "new-image-drawn":
                                            _vm.onNewImageDrawn,
                                          "image-remove": _vm.onImageRemoved,
                                          "file-choose": _vm.onFileChoose,
                                        },
                                        model: {
                                          value: _vm.myPhoto,
                                          callback: function ($$v) {
                                            _vm.myPhoto = $$v
                                          },
                                          expression: "myPhoto",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            slot: "initial",
                                            crossorigin: "anonymous",
                                            src: _vm.imageURL,
                                          },
                                          slot: "initial",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mr-2" },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass:
                                          "upload-photo mr-2 sm:mb-0 mb-2",
                                        attrs: { type: "border" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectFile()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.uploadPhotoButtonText)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "mt-2 mb-2",
                                        attrs: {
                                          disabled:
                                            !this.imageURL ||
                                            this.imageURL ===
                                              this.defaultImageURL ||
                                            this.isDefaultPhoto,
                                          type: "border",
                                          color: "danger",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removePhoto()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.deletePicture"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "upload-photo text-sm ml-25 mt-2",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "vue.fileTypeNotSupportedMessage"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "6",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mr-2",
                                    staticStyle: {
                                      "min-width": "80px",
                                      "min-height": "80px",
                                    },
                                  },
                                  [
                                    _vm.videoURL || _vm.transcodedURL
                                      ? _c(
                                          "video",
                                          {
                                            ref: "embedVideo",
                                            attrs: {
                                              width: "80",
                                              height: "80",
                                              autoplay: "",
                                              muted: "",
                                              loop: "",
                                            },
                                            domProps: { muted: true },
                                          },
                                          [
                                            _vm.embedVideoURL.indexOf(".webm") >
                                            -1
                                              ? _c("source", {
                                                  attrs: {
                                                    src: _vm.embedVideoURL,
                                                    type: _vm.webm,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.embedVideoURL.indexOf(".mp4") >
                                            -1
                                              ? _c("source", {
                                                  attrs: {
                                                    src: _vm.embedVideoURL,
                                                    type: _vm.mp4,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "vue.browserDoesNotSupportVideoTag"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("input", {
                                      ref: "uploadInput",
                                      staticStyle: { display: "none" },
                                      attrs: {
                                        type: "file",
                                        name: "file",
                                        accept: "video/*",
                                        multiple: false,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.detectVideoFile($event)
                                        },
                                      },
                                    }),
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass:
                                          "upload-photo mr-4 sm:mb-0 mb-2",
                                        attrs: {
                                          type: "border",
                                          disabled:
                                            _vm.videoURL || _vm.transcodedURL,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.uploadInput.click()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.uploadVideo"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass:
                                          "upload-photo mr-4 sm:mb-0 mt-2 mb-2",
                                        attrs: {
                                          type: "border",
                                          color: "danger",
                                          disabled:
                                            !_vm.videoURL && !_vm.transcodedURL,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteVideo()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.deleteVideo"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "upload-photo text-sm ml-25 mt-2",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "vue.videoFileTypeNotSupportedMessage"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm.uploadingVideo
                                      ? _c(
                                          "vs-progress",
                                          {
                                            staticClass: "mt-5",
                                            attrs: {
                                              percent: _vm.videoProgressUpload,
                                              color: "primary",
                                            },
                                          },
                                          [_vm._v("primary")]
                                        )
                                      : _vm._e(),
                                    _vm.transcodingVideo
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "vs-progress",
                                              {
                                                staticClass: "mt-5",
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "warning",
                                                },
                                              },
                                              [_vm._v("primary")]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "upload-photo text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "vue.transcodingVideo"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap items-center mb-base" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col w-1/3" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: _vm.$t("inputs.name") },
                              model: {
                                value: _vm.dialog.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialog, "name", $$v)
                                },
                                expression: "dialog.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap items-center mb-base" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "switchLabel",
                            class: _vm.dialog.showVideo ? "grey" : "primary",
                          },
                          [_vm._v("Show Picture")]
                        ),
                        _c("vs-switch", {
                          staticClass: "ml-3 mr-3",
                          attrs: { color: "#275D73" },
                          model: {
                            value: _vm.dialog.showVideo,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialog, "showVideo", $$v)
                            },
                            expression: "dialog.showVideo",
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "switchLabel",
                            class: _vm.dialog.showVideo ? "primary" : "grey",
                          },
                          [_vm._v(_vm._s(_vm.$t("vue.showVideo")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap items-center mb-base" },
                      [
                        _c(
                          "div",
                          { staticClass: "mr-5" },
                          [
                            _c(
                              "vs-select",
                              {
                                attrs: {
                                  autocomplete: "",
                                  color: "grey",
                                  label: _vm.$t("vue.appearance"),
                                },
                                model: {
                                  value: _vm.dialog.visibility,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dialog, "visibility", $$v)
                                  },
                                  expression: "dialog.visibility",
                                },
                              },
                              [
                                _c("vs-select-item", {
                                  attrs: {
                                    value: "always",
                                    text: _vm.$t("vue.automatically"),
                                  },
                                }),
                                _c("vs-select-item", {
                                  attrs: {
                                    value: "permission",
                                    text: _vm.$t("vue.afterApproval"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {},
                          [
                            _c(
                              "vs-select",
                              {
                                attrs: {
                                  autocomplete: "",
                                  color: "grey",
                                  label: _vm.$t("vue.dialogType"),
                                },
                                model: {
                                  value: _vm.dialog.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dialog, "type", $$v)
                                  },
                                  expression: "dialog.type",
                                },
                              },
                              [
                                _c("vs-select-item", {
                                  attrs: {
                                    value: "popup",
                                    text: _vm.$t("vue.popup"),
                                  },
                                }),
                                _c("vs-select-item", {
                                  attrs: {
                                    value: "button",
                                    text: _vm.$t("vue.button"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "vx-row mb-8" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-1/3" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "min_value:15|max_value:60",
                                expression: "'min_value:15|max_value:60'",
                              },
                            ],
                            attrs: {
                              label: _vm.$t(
                                "vue.displayContactFormAfterSeconds"
                              ),
                              name: "contactFormTimeout",
                              "data-vv-as": " ",
                              type: "number",
                            },
                            model: {
                              value: _vm.dialog.contactFormTimeout,
                              callback: function ($$v) {
                                _vm.$set(_vm.dialog, "contactFormTimeout", $$v)
                              },
                              expression: "dialog.contactFormTimeout",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("contactFormTimeout"),
                                  expression:
                                    "errors.has('contactFormTimeout')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("contactFormTimeout"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.dialog.visibility === "always"
                      ? _c("div", { staticClass: "vx-row mb-8" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-1/3" },
                            [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  label: _vm.$t("vue.displayAfterSeconds"),
                                },
                                model: {
                                  value: _vm.dialog.visibleTimeout,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dialog, "visibleTimeout", $$v)
                                  },
                                  expression: "dialog.visibleTimeout",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.dialog.type !== "button"
                      ? _c("div", { staticClass: "vx-row mb-8" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: { label: _vm.$t("inputs.title") },
                                model: {
                                  value: _vm.dialog.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dialog, "title", $$v)
                                  },
                                  expression: "dialog.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "vx-row mb-6" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { label: _vm.$t("inputs.subtitle") },
                            model: {
                              value: _vm.dialog.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.dialog, "text", $$v)
                              },
                              expression: "dialog.text",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row mb-6" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c(
                            "label",
                            { staticClass: "vs-input--label label-right" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("vue.characterCount")) +
                                  " : " +
                                  _vm._s(_vm.dialog.confirmButtonText.length) +
                                  ", " +
                                  _vm._s(_vm.$t("vue.remaining")) +
                                  ":\n              " +
                                  _vm._s(
                                    20 - _vm.dialog.confirmButtonText.length
                                  )
                              ),
                            ]
                          ),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { label: _vm.$t("inputs.confirmButton") },
                            model: {
                              value: _vm.dialog.confirmButtonText,
                              callback: function ($$v) {
                                _vm.$set(_vm.dialog, "confirmButtonText", $$v)
                              },
                              expression: "dialog.confirmButtonText",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.dialog.type !== "button"
                      ? _c("div", { staticClass: "vx-row mb-6" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c(
                                "label",
                                { staticClass: "vs-input--label label-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("vue.characterCount")) +
                                      " : " +
                                      _vm._s(
                                        _vm.dialog.cancelButtonText.length
                                      ) +
                                      ", " +
                                      _vm._s(_vm.$t("vue.remaining")) +
                                      ":\n              " +
                                      _vm._s(
                                        20 - _vm.dialog.cancelButtonText.length
                                      )
                                  ),
                                ]
                              ),
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: { label: _vm.$t("inputs.rejectButton") },
                                model: {
                                  value: _vm.dialog.cancelButtonText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialog,
                                      "cancelButtonText",
                                      $$v
                                    )
                                  },
                                  expression: "dialog.cancelButtonText",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "vx-row px-5" },
                      [
                        _c("label", { staticClass: "w-full" }, [
                          _vm._v(_vm._s(_vm.$t("vue.embedCodeText"))),
                        ]),
                        _c(
                          "prism",
                          {
                            staticClass: "rounded-lg",
                            attrs: { language: "javascript" },
                          },
                          [_vm._v(_vm._s(_vm.content))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-row mb-8 px-5" },
                      [
                        _c(
                          "vs-button",
                          {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:copy",
                                value: _vm.content,
                                expression: "content",
                                arg: "copy",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:success",
                                value: _vm.onCopy,
                                expression: "onCopy",
                                arg: "success",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:error",
                                value: _vm.onError,
                                expression: "onError",
                                arg: "error",
                              },
                            ],
                            attrs: {
                              type: "border",
                              size: "small",
                              "icon-pack": "feather",
                              icon: "icon-clipboard",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("vue.copy")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "vx-row mb-8 px-5" }, [
                      _c("div", { staticClass: "vx-col w-1/3" }),
                      _c(
                        "div",
                        { staticClass: "vx-col w-2/3" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                type: "border",
                                "icon-pack": "feather",
                                icon: "icon-lock",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showDataProtectionPopup = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("vue.dataProtectionTextButton")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-row px-5" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-3 mb-2",
                            attrs: {
                              disabled: !_vm.validateForm || _vm.loading,
                            },
                            on: { click: _vm.save },
                          },
                          [_vm._v(_vm._s(_vm.$t("vue.save")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.dialog.type !== "button"
              ? _c(
                  "div",
                  { staticClass: "vx-col w-full lg:w-1/3 mb-base" },
                  [
                    _c("div", { staticClass: "mb-3" }, [
                      _vm._v(_vm._s(_vm.$t("vue.preview"))),
                    ]),
                    _c(
                      "vx-card",
                      { staticClass: "text-center greet-user" },
                      [
                        _c("div", { staticClass: "pa-w3" }, [
                          _c("span", {
                            staticClass: "pa-w3-badge pa-w3-green",
                          }),
                        ]),
                        !_vm.dialog.showVideo
                          ? _c(
                              "div",
                              [
                                false
                                  ? _c("vs-avatar", {
                                      attrs: {
                                        color: "success",
                                        size: "70px",
                                        "icon-pack": "feather",
                                        icon: "icon-check",
                                      },
                                    })
                                  : _vm._e(),
                                _c("img", {
                                  staticClass: "center-text",
                                  staticStyle: { display: "inline" },
                                  attrs: { src: _vm.imageURL },
                                }),
                              ],
                              1
                            )
                          : _c("div", [
                              _vm.videoURL || _vm.transcodedURL
                                ? _c(
                                    "video",
                                    {
                                      ref: "embedVideo",
                                      staticStyle: { "border-radius": "5px" },
                                      attrs: {
                                        width: "200",
                                        height: "114",
                                        autoplay: "",
                                        muted: "",
                                        loop: "",
                                      },
                                      domProps: { muted: true },
                                    },
                                    [
                                      _vm.embedVideoURL.indexOf(".webm") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.embedVideoURL,
                                              type: _vm.webm,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.embedVideoURL.indexOf(".mp4") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.embedVideoURL,
                                              type: _vm.mp4,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                        _c("h2", { staticClass: "mb-5" }, [
                          _vm._v(_vm._s(_vm.dialog.title)),
                        ]),
                        _c("p", { staticClass: "mb-3" }, [
                          _vm._v(_vm._s(_vm.dialog.text)),
                        ]),
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-3 mb-2",
                            attrs: {
                              color: _vm.dialog.confirmButtonColor,
                              "text-color": _vm.dialog.confirmButtonTextColor,
                            },
                          },
                          [_vm._v(_vm._s(_vm.dialog.confirmButtonText))]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-3 mb-2",
                            attrs: { type: "flat", color: "dark" },
                          },
                          [_vm._v(_vm._s(_vm.dialog.cancelButtonText))]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mt-12" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "vs-input--label",
                              attrs: { for: "" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("vue.confirmButtonBackgroundColor")
                                )
                              ),
                            ]
                          ),
                          _c("compact-picker", {
                            attrs: { label: "abc" },
                            on: { input: _vm.updateConfirmButtonColor },
                            model: {
                              value: _vm.dialog.confirmButtonColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.dialog, "confirmButtonColor", $$v)
                              },
                              expression: "dialog.confirmButtonColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-8 col-xs-1" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "vs-input--label",
                              attrs: { for: "" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("vue.confirmButtonTextColor"))
                              ),
                            ]
                          ),
                          _c("compact-picker", {
                            attrs: { label: "abc" },
                            on: { input: _vm.updateConfirmButtonColorText },
                            model: {
                              value: _vm.dialog.confirmButtonTextColor,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dialog,
                                  "confirmButtonTextColor",
                                  $$v
                                )
                              },
                              expression: "dialog.confirmButtonTextColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dialog.type === "button"
              ? _c("div", { staticClass: "vx-col w-full lg:w-1/3 mb-base" }, [
                  _c("div", { staticClass: "mt-12" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "label",
                          {
                            staticClass: "vs-input--label",
                            attrs: { for: "" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("vue.confirmButtonBackgroundColor"))
                            ),
                          ]
                        ),
                        _c("compact-picker", {
                          attrs: { label: "abc" },
                          on: { input: _vm.updateConfirmButtonColor },
                          model: {
                            value: _vm.dialog.confirmButtonColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialog, "confirmButtonColor", $$v)
                            },
                            expression: "dialog.confirmButtonColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-8 col-xs-1" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "vs-input--label",
                            attrs: { for: "" },
                          },
                          [_vm._v(_vm._s(_vm.$t("vue.confirmButtonTextColor")))]
                        ),
                        _c("compact-picker", {
                          attrs: { label: "abc" },
                          on: { input: _vm.updateConfirmButtonColorText },
                          model: {
                            value: _vm.dialog.confirmButtonTextColor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dialog,
                                "confirmButtonTextColor",
                                $$v
                              )
                            },
                            expression: "dialog.confirmButtonTextColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", [
              _vm.dialog.type == "button"
                ? _c("div", { staticClass: "button-container" }, [
                    !_vm.dialog.showVideo
                      ? _c(
                          "div",
                          {
                            staticClass: "button-content",
                            attrs: {
                              "aria-haspopup": "dialog",
                              "aria-label": "Open new message",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button-item",
                                style:
                                  "background-color: rgb(10, 91, 255); background-image: url(" +
                                  _vm.imageURL +
                                  "); fill: rgb(255, 255, 255);",
                                attrs: { id: "widgetButton" },
                              },
                              [
                                _c("div", {
                                  staticClass: "darkenButton",
                                  staticStyle: { "z-index": "1" },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "speech-bubble-container image" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "speech-bubble",
                                    attrs: {
                                      role: "dialog",
                                      "aria-label":
                                        "You have received a message",
                                    },
                                  },
                                  [
                                    _vm._m(0),
                                    _c(
                                      "div",
                                      { staticClass: "speech-bubble-item" },
                                      [
                                        _c("span", {
                                          staticClass: "speech-bubble-arrow",
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "speech-bubble-text-container",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "speech-bubble-text",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.dialog.text)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "pa-button-confirm",
                                                style:
                                                  "color: " +
                                                  _vm.dialog
                                                    .confirmButtonTextColor +
                                                  ";background-color: " +
                                                  _vm.dialog
                                                    .confirmButtonColor +
                                                  ";",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.dialog
                                                        .confirmButtonText
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.dialog.showVideo
                      ? _c(
                          "div",
                          {
                            staticClass: "button-content",
                            attrs: {
                              "aria-haspopup": "dialog",
                              "aria-label": "Open new message",
                            },
                          },
                          [
                            _c("div", { staticClass: "pa-w3" }, [
                              _c("span", {
                                staticClass: "pa-w3-badge pa-w3-green",
                              }),
                              _vm.videoURL || _vm.transcodedURL
                                ? _c(
                                    "video",
                                    {
                                      ref: "embedVideo",
                                      staticClass: "video-item",
                                      attrs: {
                                        width: "200",
                                        height: "114",
                                        autoplay: "",
                                        muted: "",
                                        loop: "",
                                      },
                                      domProps: { muted: true },
                                    },
                                    [
                                      _vm.embedVideoURL.indexOf(".webm") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.embedVideoURL,
                                              type: _vm.webm,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.embedVideoURL.indexOf(".mp4") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.embedVideoURL,
                                              type: _vm.mp4,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "button-item",
                                      style:
                                        "margin: 0;background-color: rgb(10, 91, 255); background-image: url(" +
                                        _vm.imageURL +
                                        "); fill: rgb(255, 255, 255);",
                                      attrs: { id: "widgetButton" },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "darkenButton",
                                        staticStyle: { "z-index": "1" },
                                      }),
                                    ]
                                  ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "speech-bubble-container video" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "speech-bubble",
                                    attrs: {
                                      role: "dialog",
                                      "aria-label":
                                        "You have received a message",
                                    },
                                  },
                                  [
                                    _vm._m(1),
                                    _c(
                                      "div",
                                      { staticClass: "speech-bubble-item" },
                                      [
                                        _c("span", {
                                          staticClass: "speech-bubble-arrow",
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "speech-bubble-text-container",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "speech-bubble-text",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.dialog.text)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "pa-button-confirm",
                                                style:
                                                  "color: " +
                                                  _vm.dialog
                                                    .confirmButtonTextColor +
                                                  ";background-color: " +
                                                  _vm.dialog
                                                    .confirmButtonColor +
                                                  ";",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.dialog
                                                        .confirmButtonText
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "pa-close-button",
        attrs: { title: "Dismiss", "aria-label": "Dismiss" },
      },
      [_c("a", { staticClass: "pa-close-thik", attrs: { href: "#" } })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "pa-close-button",
        attrs: { title: "Dismiss", "aria-label": "Dismiss" },
      },
      [_c("a", { staticClass: "pa-close-thik", attrs: { href: "#" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }