<template>
  <div v-if="dialog">
    <vs-popup :title="$t('vue.dataProtectionHeadline')" :active.sync="showDataProtectionPopup">
      <div v-html="dataProtectionTextHtml"></div>
      <vs-button
        class="float-right mt-5"
        type="border"
        size="small"
        icon-pack="feather"
        icon="icon-clipboard"
        v-clipboard:copy="$t('vue.dataProtectionText')"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        {{ $t('vue.copy') }}
      </vs-button>
    </vs-popup>
    <div class="vx-row">
      <div class="vx-col w-full lg:w-2/3 mb-base">
        <vx-card :title="$t('vue.configureDialog')">
          <!-- Img Row -->
          <div class="flex flex-wrap items-center mb-base">
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                <div class="mr-2" style="min-width: 80px; min-height: 80px">
                  <croppa
                    v-show="myPhoto.img"
                    v-model="myPhoto"
                    placeholder="Click here"
                    :show-remove-button="false"
                    canvas-color="white"
                    prevent-white-space
                    initial-size="contain"
                    :disable="false"
                    accept=".jpeg,.jpg,.gif"
                    :width="80"
                    :height="80"
                    :file-size-limit="204800"
                    :placeholder-font-size="14"
                    @file-type-mismatch="onFileTypeMismatch"
                    @file-size-exceed="onFileSizeExceed"
                    @new-image-drawn="onNewImageDrawn"
                    @image-remove="onImageRemoved"
                    @file-choose="onFileChoose"
                  >
                    <img slot="initial" crossorigin="anonymous" :src="imageURL" />
                  </croppa>
                </div>
                <div class="mr-2">
                  <vs-button type="border" class="upload-photo mr-2 sm:mb-0 mb-2" @click="selectFile()">{{ uploadPhotoButtonText }}</vs-button>
                  <vs-button
                    :disabled="!this.imageURL || this.imageURL === this.defaultImageURL || this.isDefaultPhoto"
                    type="border"
                    color="danger"
                    class="mt-2 mb-2"
                    @click="removePhoto()"
                    >{{ $t('vue.deletePicture') }}</vs-button
                  >
                  <p class="upload-photo text-sm ml-25 mt-2">{{ $t('vue.fileTypeNotSupportedMessage') }}</p>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                <div class="mr-2" style="min-width: 80px; min-height: 80px">
                  <video ref="embedVideo" v-if="videoURL || transcodedURL" width="80" height="80" autoplay muted loop>
                    <source v-if="embedVideoURL.indexOf('.webm') > -1" :src="embedVideoURL" :type="webm" />
                    <source v-if="embedVideoURL.indexOf('.mp4') > -1" :src="embedVideoURL" :type="mp4" />
                    {{ $t('vue.browserDoesNotSupportVideoTag') }}
                  </video>
                </div>
                <div>
                  <input style="display: none" type="file" name="file" ref="uploadInput" accept="video/*" :multiple="false" @change="detectVideoFile($event)" />
                  <vs-button type="border" class="upload-photo mr-4 sm:mb-0 mb-2" :disabled="videoURL || transcodedURL" @click="$refs.uploadInput.click()">{{
                    $t('vue.uploadVideo')
                  }}</vs-button>
                  <vs-button
                    type="border"
                    color="danger"
                    class="upload-photo mr-4 sm:mb-0 mt-2 mb-2"
                    :disabled="!videoURL && !transcodedURL"
                    @click="deleteVideo()"
                    >{{ $t('vue.deleteVideo') }}</vs-button
                  >
                  <p class="upload-photo text-sm ml-25 mt-2">{{ $t('vue.videoFileTypeNotSupportedMessage') }}</p>
                  <vs-progress class="mt-5" v-if="uploadingVideo" :percent="videoProgressUpload" color="primary">primary</vs-progress>
                  <div v-if="transcodingVideo">
                    <vs-progress class="mt-5" indeterminate color="warning">primary</vs-progress>
                    <p class="upload-photo text-sm">{{ $t('vue.transcodingVideo') }}</p>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="flex flex-wrap items-center mb-base">
            <div class="vx-col w-1/3">
              <vs-input class="w-full" :label="$t('inputs.name')" v-model="dialog.name" />
            </div>
          </div>

          <div class="flex flex-wrap items-center mb-base">
            <label class="switchLabel" :class="dialog.showVideo ? 'grey' : 'primary'">Show Picture</label>
            <vs-switch color="#275D73" class="ml-3 mr-3" v-model="dialog.showVideo"></vs-switch>
            <label class="switchLabel" :class="dialog.showVideo ? 'primary' : 'grey'">{{ $t('vue.showVideo') }}</label>
          </div>

          <div class="flex flex-wrap items-center mb-base">
            <div class="mr-5">
              <vs-select v-model="dialog.visibility" autocomplete color="grey" :label="$t('vue.appearance')">
                <vs-select-item value="always" :text="$t('vue.automatically')" />
                <vs-select-item value="permission" :text="$t('vue.afterApproval')" />
              </vs-select>
            </div>
            <div class="">
              <vs-select v-model="dialog.type" autocomplete color="grey" :label="$t('vue.dialogType')">
                <vs-select-item value="popup" :text="$t('vue.popup')" />
                <vs-select-item value="button" :text="$t('vue.button')" />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mb-8">
            <div class="vx-col w-1/3">
              <vs-input
                :label="$t('vue.displayContactFormAfterSeconds')"
                name="contactFormTimeout"
                :data-vv-as="' '"
                type="number"
                v-validate="'min_value:15|max_value:60'"
                v-model="dialog.contactFormTimeout"
              />
              <span class="text-danger text-sm" v-show="errors.has('contactFormTimeout')">{{ errors.first('contactFormTimeout') }}</span>
            </div>
          </div>

          <div v-if="dialog.visibility === 'always'" class="vx-row mb-8">
            <div class="vx-col w-1/3">
              <vs-input class="w-full" :label="$t('vue.displayAfterSeconds')" v-model="dialog.visibleTimeout" />
            </div>
          </div>

          <div v-if="dialog.type !== 'button'" class="vx-row mb-8">
            <div class="vx-col w-full">
              <vs-input class="w-full" :label="$t('inputs.title')" v-model="dialog.title" />
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input class="w-full" :label="$t('inputs.subtitle')" v-model="dialog.text" />
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label label-right"
                >{{ $t('vue.characterCount') }} : {{ dialog.confirmButtonText.length }}, {{ $t('vue.remaining') }}:
                {{ 20 - dialog.confirmButtonText.length }}</label
              >
              <vs-input class="w-full" :label="$t('inputs.confirmButton')" v-model="dialog.confirmButtonText" />
            </div>
          </div>

          <div v-if="dialog.type !== 'button'" class="vx-row mb-6">
            <div class="vx-col w-full">
              <label class="vs-input--label label-right"
                >{{ $t('vue.characterCount') }} : {{ dialog.cancelButtonText.length }}, {{ $t('vue.remaining') }}:
                {{ 20 - dialog.cancelButtonText.length }}</label
              >
              <vs-input class="w-full" :label="$t('inputs.rejectButton')" v-model="dialog.cancelButtonText" />
            </div>
          </div>

          <div class="vx-row px-5">
            <label class="w-full">{{ $t('vue.embedCodeText') }}</label>
            <prism class="rounded-lg" language="javascript">{{ content }}</prism>
          </div>
          <div class="vx-row mb-8 px-5">
            <vs-button
              type="border"
              size="small"
              icon-pack="feather"
              icon="icon-clipboard"
              v-clipboard:copy="content"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              {{ $t('vue.copy') }}
            </vs-button>
          </div>

          <div class="vx-row mb-8 px-5">
            <div class="vx-col w-1/3"></div>
            <div class="vx-col w-2/3">
              <vs-button type="border" icon-pack="feather" icon="icon-lock" @click="showDataProtectionPopup = true">
                {{ $t('vue.dataProtectionTextButton') }}
              </vs-button>
            </div>
          </div>

          <div class="vx-row px-5">
            <vs-button @click="save" :disabled="!validateForm || loading" class="mr-3 mb-2">{{ $t('vue.save') }}</vs-button>
          </div>
        </vx-card>
      </div>
      <div v-if="dialog.type !== 'button'" class="vx-col w-full lg:w-1/3 mb-base">
        <div class="mb-3">{{ $t('vue.preview') }}</div>
        <vx-card class="text-center greet-user">
          <div class="pa-w3"><span class="pa-w3-badge pa-w3-green"></span></div>
          <div v-if="!dialog.showVideo">
            <vs-avatar v-if="false" color="success" size="70px" icon-pack="feather" icon="icon-check" />
            <img style="display: inline" class="center-text" :src="imageURL" />
          </div>
          <div v-else>
            <video ref="embedVideo" style="border-radius: 5px" v-if="videoURL || transcodedURL" width="200" height="114" autoplay muted loop>
              <source v-if="embedVideoURL.indexOf('.webm') > -1" :src="embedVideoURL" :type="webm" />
              <source v-if="embedVideoURL.indexOf('.mp4') > -1" :src="embedVideoURL" :type="mp4" />
            </video>
          </div>
          <h2 class="mb-5">{{ dialog.title }}</h2>
          <p class="mb-3">{{ dialog.text }}</p>
          <vs-button :color="dialog.confirmButtonColor" :text-color="dialog.confirmButtonTextColor" class="mr-3 mb-2">{{ dialog.confirmButtonText }}</vs-button>
          <vs-button type="flat" color="dark" class="mr-3 mb-2">{{ dialog.cancelButtonText }}</vs-button>
        </vx-card>
        <div class="mt-12">
          <div>
            <label for="" class="vs-input--label">{{ $t('vue.confirmButtonBackgroundColor') }}</label>
            <compact-picker label="abc" @input="updateConfirmButtonColor" v-model="dialog.confirmButtonColor" />
          </div>
          <div class="mt-8 col-xs-1">
            <label for="" class="vs-input--label">{{ $t('vue.confirmButtonTextColor') }}</label>
            <compact-picker label="abc" @input="updateConfirmButtonColorText" v-model="dialog.confirmButtonTextColor" />
          </div>
        </div>
      </div>
      <div v-if="dialog.type === 'button'" class="vx-col w-full lg:w-1/3 mb-base">
        <div class="mt-12">
          <div>
            <label for="" class="vs-input--label">{{ $t('vue.confirmButtonBackgroundColor') }}</label>
            <compact-picker label="abc" @input="updateConfirmButtonColor" v-model="dialog.confirmButtonColor" />
          </div>
          <div class="mt-8 col-xs-1">
            <label for="" class="vs-input--label">{{ $t('vue.confirmButtonTextColor') }}</label>
            <compact-picker label="abc" @input="updateConfirmButtonColorText" v-model="dialog.confirmButtonTextColor" />
          </div>
        </div>
      </div>
      <div>
        <div v-if="dialog.type == 'button'" class="button-container">
          <div v-if="!dialog.showVideo" aria-haspopup="dialog" aria-label="Open new message" class="button-content">
            <button
              class="button-item"
              id="widgetButton"
              :style="'background-color: rgb(10, 91, 255); background-image: url(' + imageURL + '); fill: rgb(255, 255, 255);'"
            >
              <div class="darkenButton" style="z-index: 1"></div>
            </button>
            <div class="speech-bubble-container image">
              <div class="speech-bubble" role="dialog" aria-label="You have received a message">
                <button class="pa-close-button" title="Dismiss" aria-label="Dismiss">
                  <a href="#" class="pa-close-thik"></a>
                </button>
                <div class="speech-bubble-item">
                  <span class="speech-bubble-arrow"></span>
                  <div class="speech-bubble-text-container">
                    <div class="speech-bubble-text">
                      <span>{{ dialog.text }}</span>
                    </div>
                    <button class="pa-button-confirm" :style="`color: ${dialog.confirmButtonTextColor};background-color: ${dialog.confirmButtonColor};`">
                      <p>{{ dialog.confirmButtonText }}</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dialog.showVideo" aria-haspopup="dialog" aria-label="Open new message" class="button-content">
            <div class="pa-w3">
              <span class="pa-w3-badge pa-w3-green"></span>
              <video class="video-item" ref="embedVideo" v-if="videoURL || transcodedURL" width="200" height="114" autoplay muted loop>
                <source v-if="embedVideoURL.indexOf('.webm') > -1" :src="embedVideoURL" :type="webm" />
                <source v-if="embedVideoURL.indexOf('.mp4') > -1" :src="embedVideoURL" :type="mp4" />
              </video>
              <button
                v-else
                class="button-item"
                id="widgetButton"
                :style="'margin: 0;background-color: rgb(10, 91, 255); background-image: url(' + imageURL + '); fill: rgb(255, 255, 255);'"
              >
                <div class="darkenButton" style="z-index: 1"></div>
              </button>
            </div>
            <div class="speech-bubble-container video">
              <div class="speech-bubble" role="dialog" aria-label="You have received a message">
                <button class="pa-close-button" title="Dismiss" aria-label="Dismiss">
                  <a href="#" class="pa-close-thik"></a>
                </button>
                <div class="speech-bubble-item">
                  <span class="speech-bubble-arrow"></span>
                  <div class="speech-bubble-text-container">
                    <div class="speech-bubble-text">
                      <span>{{ dialog.text }}</span>
                    </div>
                    <button class="pa-button-confirm" :style="`color: ${dialog.confirmButtonTextColor};background-color: ${dialog.confirmButtonColor};`">
                      <p>{{ dialog.confirmButtonText }}</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import Prism from 'vue-prism-component'
import { Compact } from 'vue-color'

export default {
  watch: {
    videoUploadTask() {
      const vm = this
      this.videoUploadTask.on(
        'state_changed',
        (sp) => {
          this.uploadingVideo = true
          this.videoProgressUpload = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100)
        },
        null,
        () => {
          this.videoUploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            this.uploadingVideo = false
            if (downloadURL.indexOf('.mpg') > -1 || downloadURL.indexOf('.mpeg') > -1) {
              this.videoURL = null
              this.dialog.videoURL = null
            } else {
              this.videoURL = downloadURL
              this.dialog.videoURL = downloadURL
            }
            this.transcodedURL = null
            await vm.$db.collection('dialogs').doc(this.dialogId).set({ videoURL: downloadURL, transcodedURL: null }, { merge: true })
          })
        }
      )
    },
    'dialog.confirmButtonText'() {
      if (this.dialog.confirmButtonText.length > 20) {
        this.dialog.confirmButtonText = this.dialog.confirmButtonText.substring(0, 20)
      }
    },
    'dialog.cancelButtonText'() {
      if (this.dialog.cancelButtonText.length > 20) {
        this.dialog.cancelButtonText = this.dialog.cancelButtonText.substring(0, 20)
      }
    }
  },
  data() {
    return {
      dialogId: null,
      dialog: null,
      companyId: '',
      loading: false,
      content: '',
      isPhotoSelected: false,
      isDefaultPhoto: false,
      myPhoto: {},
      uploadTask: false,
      uploadPhotoButtonText: this.$i18n.t('vue.selectPicture'),
      imageURL: false,
      showImage: false,
      progressUpload: 0,
      videoUploadTask: false,
      videoProgressUpload: 0,
      videoFileName: '',
      uploadingVideo: false,
      videoURL: null,
      transcodedURL: null,
      transcodingVideo: false,
      displayedMessage: false,
      webm: 'video/webm',
      mp4: 'video/mp4',
      showDataProtectionPopup: false
    }
  },
  async mounted() {
    const vm = this
    //vm.$vs.loading()
    vm.dialogId = vm.$route.params.id
    vm.companyId = vm.activeUserInfo.company
    vm.getDialogInfo(vm.dialogId)
    vm.$vs.loading.close()
    /* eslint-disable */
    vm.content = `<script type="text/javascript" async>(function(d,s){
var l=d.createElement(s),e=d.getElementsByTagName(s)[0];
l.async=true;l.type='text/javascript';
l.src='${vm.$config.hostUrl}/embed.js';l.id='${vm.dialogId}';
e.parentNode.insertBefore(l,e);})(document,'script');<\/script>`
    /* eslint-enable */

    const videoTranscodeStatusRef = firebase.database().ref(`video-transcode/${vm.dialogId}`)
    videoTranscodeStatusRef.set({ success: false })
    videoTranscodeStatusRef.on('value', (snapshot) => {
      const val = snapshot.val()
      if (!val.success && val.error && !vm.displayedMessage) {
        vm.displayedMessage = true
        vm.transcodingVideo = false
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.transcodingVideoError'),
          text: val.error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      if (val.started) {
        vm.transcodingVideo = true
      }

      if (val.success === true && val.signedUrl) {
        vm.transcodingVideo = false
        vm.transcodedURL = val.signedUrl
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.success'),
          text: vm.$i18n.t('vue.transcodedVideoSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
        if (vm.$refs.embedVideo) {
          vm.$refs.embedVideo.currentTime = 0
          vm.$refs.embedVideo.load()
        }
      }
    })
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.dialog.title !== ''
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    embedVideoURL() {
      return this.defaultVideoURL
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },
    dataProtectionTextHtml() {
      return this.$i18n.t('vue.dataProtectionNote') + this.$i18n.t('vue.dataProtectionText')
    },
    defaultImageURL() {
      return '/user-icon.png'
    }
  },
  methods: {
    async getDialogInfo(dialogId) {
      const dialogRef = await this.$db.collection('dialogs').doc(dialogId).get()
      if (dialogRef && dialogRef.data()) {
        this.dialog = dialogRef.data()
        if (this.dialog && !this.dialog.created) {
          this.dialog.created = new Date()
        }
        if (this.dialog.imageURL) {
          this.imageURL = this.dialog.imageURL
          this.isDefaultPhoto = false
        } else {
          this.imageURL = this.defaultImageURL
          this.isDefaultPhoto = true
        }
        if (this.dialog.videoURL) {
          this.videoURL = this.dialog.videoURL
        }
        if (this.dialog.transcodedURL) {
          this.transcodedURL = this.dialog.transcodedURL
        }
      }
    },
    onFileChoose() {
      this.isPhotoSelected = true
      setTimeout(() => {
        this.isDefaultPhoto = false
        if (this.isPhotoSelected) {
          this.uploadCroppedImage()
        }
      }, 200)
    },
    async deleteVideo() {
      const vm = this
      vm.$vs.loading()
      const storageService = firebase.storage()
      try {
        if (this.videoURL) {
          await storageService.refFromURL(this.videoURL).delete()
          const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
          await dialogRef.set({ videoURL: null }, { merge: true })
          this.videoURL = null
        }
      } catch (err) {
        let message = err.message
        if (err.code === 'storage/object-not-found') {
          message = vm.$i18n.t('vue.objectDoesNotExists')
          const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
          await dialogRef.set({ videoURL: null }, { merge: true })
          this.videoURL = null
        }
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      try {
        if (this.transcodedURL) {
          await storageService.refFromURL(this.transcodedURL).delete()
          const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
          await dialogRef.set({ transcodedURL: null }, { merge: true })
          this.transcodedURL = null
        }
      } catch (err) {
        let message = err.message
        if (err.code === 'storage/object-not-found') {
          message = vm.$i18n.t('vue.objectDoesNotExists')
          const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
          await dialogRef.set({ transcodedURL: null }, { merge: true })
          this.transcodedURL = null
        }
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      vm.$vs.loading.close()
    },
    detectVideoFile(e) {
      const vm = this
      const fileList = e.target.files || e.dataTransfer.files
      Array.from(Array(fileList.length).keys()).map((x) => {
        const file = fileList[x]
        if (file.size / (1024 * 1024) > 10) {
          this.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.fileSizeLimitExceeded'),
            text: vm.$i18n.t('vue.maximumVideoSize'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          return
        }

        if (file.type.indexOf('video') === -1) {
          this.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.fileTypeNotSupported'),
            text: vm.$i18n.t('vue.videoFileTypeNotSupportedMessage'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          return
        }
        vm.displayedMessage = false
        vm.videoURL = null
        vm.transcodedURL = null
        vm.uploadVideo(file)
      })
    },
    uploadVideo(file) {
      this.videoFileName = file.name
      this.uploadingVideo = true
      const storageService = firebase.storage()
      const storageRef = storageService.ref()
      const metadata = {
        contentType: 'video/mp4',
        cacheControl: 'max-age=604800, public',
        customMetadata: {
          uid: this.activeUserInfo.uid,
          dialogId: this.dialogId
        }
      }
      this.videoUploadTask = storageRef.child(`videos/${file.name}`).put(file, metadata)
      this.$refs.uploadInput.value = ''
    },
    async save() {
      this.loading = true
      await this.$db.collection('dialogs').doc(this.dialogId).set(this.dialog, { merge: true })
      this.loading = false
      this.$vs.notify({
        title: 'Success',
        text: 'Dialog successfully saved',
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-center',
        icon: 'icon-check-circle'
      })
    },
    updateConfirmButtonColor(color) {
      this.dialog.confirmButtonColor = color.hex
    },
    updateConfirmButtonColorText(color) {
      this.dialog.confirmButtonTextColor = color.hex
    },
    onCopy() {
      this.$vs.notify({
        title: 'Success',
        text: 'Text copied successfully',
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onError() {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying text',
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    },
    onNewImageDrawn() {
      if (this.isDefaultPhoto) {
        this.uploadPhotoButtonText = this.$i18n.t('vue.selectPicture')
      } else {
        this.uploadPhotoButtonText = this.$i18n.t('vue.uploadPicture')
      }
    },
    onImageRemoved() {
      this.uploadPhotoButtonText = this.$i18n.t('vue.selectPicture')
    },
    async removePhoto() {
      const vm = this
      this.myPhoto.remove()
      const storageService = firebase.storage()
      try {
        await storageService.refFromURL(this.imageURL).delete()
        this.isDefaultPhoto = true
        this.imageURL = this.defaultImageURL
        this.dialog.imageURL = null
        const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
        await dialogRef.set({ imageURL: null }, { merge: true })
      } catch (err) {
        let message = err.message
        if (err.code === 'storage/object-not-found') {
          message = vm.$i18n.t('vue.objectDoesNotExists')
        }
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    onFileTypeMismatch() {
      const vm = this
      vm.isPhotoSelected = false
      this.$vs.notify({
        time: 8800,
        title: vm.$i18n.t('vue.fileTypeNotSupported'),
        text: vm.$i18n.t('vue.fileTypeNotSupportedMessage'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    onFileSizeExceed() {
      const vm = this
      vm.isPhotoSelected = false
      this.$vs.notify({
        time: 8800,
        title: vm.$i18n.t('vue.fileSizeLimitExceeded'),
        text: vm.$i18n.t('vue.maximumSize'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    async selectFile() {
      if ((this.isPhotoSelected || (this.imageURL && this.imageURL !== this.defaultImageURL)) && !this.isDefaultPhoto) {
        await this.uploadCroppedImage()
      } else {
        this.myPhoto.chooseFile()
        this.showImage = true
      }
    },
    async uploadCroppedImage() {
      const vm = this
      vm.$vs.loading()
      vm.isPhotoSelected = false

      try {
        this.myPhoto.generateBlob(
          async (blob) => {
            const storageService = firebase.storage()
            const storageRef = storageService.ref()
            const file = blob

            const metadata = {
              contentType: 'image/png',
              cacheControl: 'max-age=604800, public'
            }

            let uploadTask = null

            uploadTask = storageRef.child(`images/dialogs/${this.dialogId}`).put(file, metadata)
            this.uploadTask = true

            uploadTask.on(
              firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.progressUpload = progress
              },
              function (error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    vm.text = 'fields.storage/unauthorized'
                    break
                  case 'storage/canceled':
                    // User canceled the upload
                    vm.text = 'fields.storage/canceled'
                    break
                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    vm.text = 'fields.storage/unknown'
                    break
                }

                vm.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.error'),
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })

                vm.uploadTask = false
              },
              async () => {
                // Upload completed successfully, now we can get the download URL
                this.uploadTask = false
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
                const dialogRef = await this.$db.collection('dialogs').doc(this.dialogId)
                dialogRef.set({ imageURL: downloadURL }, { merge: true })
                this.$vs.loading.close()
                this.imageURL = downloadURL
                this.dialog.imageURL = downloadURL
                this.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.success'),
                  text: vm.$i18n.t('vue.photoSavedSuccessfully'),
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }
            )
          },
          'image/jpg',
          0.8
        ) // 80% compressed jpeg file
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        /* eslint-disable no-console */
        console.error(err.message)
      }
    }
  },
  components: {
    Prism,
    'compact-picker': Compact
  }
}
</script>
<style lang="scss">
.float-right {
  float: right !important;
}
.switchLabel {
  font-size: 1.2em;
  font-weight: 500;
}
.grey {
  color: grey;
}
.primary {
  color: rgba(var(--vs-primary), 1) !important;
}
.vs-progress-primary {
  min-width: 340px !important;
}
.button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: flex;

  .button-content {
    position: relative;
    padding: 0.75rem;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: end;
    align-items: flex-end;
    display: flex;
  }

  .button-item {
    margin-left: 0.75rem;
    position: relative;
    border-radius: 0.3125rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 0;
    border: none;
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    background-color: transparent;
    background-clip: padding-box;
    cursor: pointer;
    position: relative;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    display: flex;
    z-index: 2;
  }

  .video-item {
    position: relative;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 0;
    border: none;
    flex-shrink: 0;
    width: 200px;
    height: 114px;
    background-color: transparent;
    background-clip: padding-box;
    cursor: pointer;
    position: relative;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    display: flex;
    z-index: 2;
    border-radius: 5px;
  }

  .darkenButton {
    top: 0;
    height: 0;
    width: 0;
    transition: background-color 0.25s;
  }
  .badge {
    display: table-cell;
    overflow: hidden;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 100%;
    border: 3px solid #fff;
    text-align: center;
    vertical-align: middle;
    background-color: #ff001f;
    color: #fff;
    background-clip: padding-box;
    box-sizing: border-box;
  }
  .speech-bubble-container.image {
    display: flex;
    max-width: 335px;
    min-width: 132px;
    margin-bottom: 25px;
    justify-content: flex-end;
  }
  .speech-bubble-container.video {
    display: flex;
    max-width: 335px;
    margin-bottom: 80px;
    margin-right: 10px;
    min-width: 85px;
    justify-content: flex-end;
  }
  .speech-bubble {
    display: flex;
  }
  .speech-bubble-item {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    -ms-overflow-style: -ms-;
  }
  .speech-bubble-arrow {
    display: block;
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    right: -10px;
    bottom: 10px;
    background-color: inherit;
    transform: translateX(-6px) rotate(45deg);
    pointer-events: none;
    box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.2);
  }
  .speech-bubble-text-container {
    overflow: hidden;
    position: relative;
    border-radius: inherit;
    cursor: pointer;
  }
  .speech-bubble-text {
    font-size: var(--font-size-copy);
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1rem;
    font-size: 1rem;
    white-space: normal;
  }
  .speech-bubble-text span {
    text-align: justify;
    text-justify: inter-word;
    display: inline-block;
  }
  .pa-close-button {
    width: 10px;
    height: 10px;
    color: #777;
    font: 14px/100% arial, sans-serif;
    right: 5px;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
    top: -15px;
    border: 0px;
    position: relative;
    right: -15px;
  }

  .pa-close-thik:after {
    content: '✖'; /* UTF-8 symbol */
  }

  .pa-close-button a {
    color: black;
    opacity: 0.6;
  }
  .speech-bubble-container:hover .close-button {
    opacity: 1;
  }
}
.pa-w3 {
  background-color: white;
}
.pa-w3-badge {
  position: relative;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 5px;
}
.pa-w3-badge-text {
  margin-left: 5px;
}
.pa-w3-green,
.pa-w3-hover-green:hover {
  color: #fff !important;
  background-color: #4caf50 !important;
}
.label-right {
  text-align: right;
  display: block;
  position: absolute;
  width: 100%;
  right: 25px;
}
.pa-button-confirm {
  border-radius: 5px;
  margin: 5px;
  float: right;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  border: none;
}
.pa-button-confirm p {
  padding: 7px 20px;
  margin: 0;
  direction: rtl;
}
</style>
